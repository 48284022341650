<script setup>
import { ref, computed, nextTick } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import Logo from '@assets/logos/logo.svg';
import HamburgerIcon from '@assets/icons/hamburger.svg';
import SearchIcon from '@assets/icons/search.svg';
import CartIcon from '@assets/icons/cart.svg';
import UserIcon from '@assets/icons/user.svg';
import { useCart } from '@/Composables/useCart';
import { useCurrentLocale } from '@/Composables/useCurrentLocale';
import { capitalize } from 'lodash';
import SearchMenu from './SearchMenu.vue';
import Menu from './Menu.vue';
import ProductTypesMenu from './ProductTypesMenu.vue';
import MaterialsMenu from './MaterialsMenu.vue';
import AccountMenu from './AccountMenu.vue';

const isMenuOpen = ref(false);
const isSearchMenuOpen = ref(false);
const isProductTypesMenuOpen = ref(false);
const isMaterialsMenuOpen = ref(false);
const isAccountMenuOpen = ref(false);
const menu = ref(null);
const searchMenu = ref(null);
const productTypesMenu = ref(null);
const materialsMenu = ref(null);
const accountMenu = ref(null);

const currentLocale = computed(() => useCurrentLocale());
const cart = computed(() => useCart());
const cartItems = computed(() => cart.value?.getItemCount() || 0);
const locales = computed(() => usePage().props.config.locales);

const showMenu = () => (isMenuOpen.value = true);

const closeMenu = () => (isMenuOpen.value = false);

const showSearchMenu = () => {
    closeMenu();

    isSearchMenuOpen.value = true;

    nextTick(() => searchMenu.value?.focus());
};

const closeSearchMenu = () => (isSearchMenuOpen.value = false);

const showProductTypesMenu = () => {
    closeMenu();

    isProductTypesMenuOpen.value = true;
};

const closeProductTypesMenu = () => (isProductTypesMenuOpen.value = false);

const showMaterialsMenu = () => {
    closeMenu();

    isMaterialsMenuOpen.value = true;
};

const closeMaterialsMenu = () => (isMaterialsMenuOpen.value = false);

const showAccountMenu = () => {
    closeMenu();

    isAccountMenuOpen.value = true;
};

const closeAccountMenu = () => (isAccountMenuOpen.value = false);

const changeLocale = (locale) => {
    const currentLocation = window.location.href;
    const newLocation = currentLocation.replace(
        currentLocale.value.locale,
        locale,
    );

    window.location.href = newLocation;
};
</script>

<template>
    <nav class="fixed top-0 z-10 w-full">
        <div class="bg-base-100">
            <div class="container mx-auto navbar">
                <div class="navbar-start">
                    <Link class="text-xl btn btn-ghost" :href="route('home')">
                        <Logo class="w-auto h-8 text-primary" />
                    </Link>
                </div>
                <div class="navbar-end">
                    <button class="btn btn-ghost lg:hidden" @click="showMenu">
                        <HamburgerIcon class="w-8 h-8 text-primary" />
                    </button>
                    <div
                        class="items-center justify-center hidden gap-2 lg:flex"
                    >
                        <Link
                            class="rounded-full btn btn-sm btn-primary"
                            :href="route('shop.index')"
                        >
                            {{ $t('navigation.shop') }}
                        </Link>
                        <button
                            type="button"
                            class="rounded-full btn btn-sm btn-ghost text-primary"
                            @click="showProductTypesMenu"
                        >
                            {{ $t('navigation.product_range') }}
                        </button>
                        <button
                            type="button"
                            class="rounded-full btn btn-sm btn-ghost text-primary"
                            @click="showMaterialsMenu"
                        >
                            {{ $t('navigation.material') }}
                        </button>
                        <Link
                            class="rounded-full btn btn-sm btn-ghost text-primary"
                            href="#"
                        >
                            {{ $t('navigation.services') }}
                        </Link>
                        <Link
                            class="rounded-full btn btn-sm btn-ghost text-primary"
                            href="#"
                        >
                            {{ $t('navigation.about') }}
                        </Link>
                        <Link
                            class="rounded-full btn btn-sm btn-ghost text-primary"
                            href="#"
                        >
                            {{ $t('navigation.support') }}
                        </Link>
                        <button
                            type="button"
                            class="rounded-full btn btn-ghost text-primary"
                            :aria-label="$t('navigation.search')"
                            @click="showSearchMenu"
                        >
                            <SearchIcon class="w-6 h-6" />
                        </button>
                        <Link
                            class="rounded-full btn btn-ghost text-primary"
                            :href="route('cart.index')"
                            :aria-label="$t('navigation.cart')"
                        >
                            <div class="indicator">
                                <CartIcon class="w-6 h-6" />
                                <span
                                    v-if="cartItems"
                                    class="badge badge-success badge-xs indicator-item"
                                >
                                </span>
                            </div>
                        </Link>
                        <button
                            type="button"
                            class="rounded-full btn btn-ghost text-primary"
                            :aria-label="$t('navigation.account')"
                            @click="showAccountMenu"
                        >
                            <UserIcon class="w-6 h-6" />
                        </button>
                        <div class="dropdown dropdown-end">
                            <div
                                tabindex="0"
                                role="button"
                                class="btn-primary btn btn-outline btn-sm text-nowrap"
                            >
                                {{ capitalize(currentLocale.language) }}
                            </div>
                            <ul
                                tabindex="0"
                                class="w-56 p-2 shadow dropdown-content menu bg-base-100 rounded-box"
                            >
                                <li
                                    v-for="locale in locales"
                                    :key="locale.locale"
                                >
                                    <a
                                        :class="{
                                            active:
                                                locale.locale ===
                                                currentLocale.locale,
                                        }"
                                        @click="changeLocale(locale.locale)"
                                    >
                                        {{ $t('language.' + locale.locale) }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Menu
            ref="menu"
            :is-open="isMenuOpen"
            @close="closeMenu"
            @show-search-menu="showSearchMenu"
            @show-product-types-menu="showProductTypesMenu"
            @show-materials-menu="showMaterialsMenu"
            @change-locale="changeLocale"
        />
        <SearchMenu
            ref="searchMenu"
            :is-open="isSearchMenuOpen"
            @close="closeSearchMenu"
        />
        <ProductTypesMenu
            ref="productTypesMenu"
            :is-open="isProductTypesMenuOpen"
            @close="closeProductTypesMenu"
        />
        <MaterialsMenu
            ref="materialsMenu"
            :is-open="isMaterialsMenuOpen"
            @close="closeMaterialsMenu"
        />
        <AccountMenu
            ref="accountMenu"
            :is-open="isAccountMenuOpen"
            @close="closeAccountMenu"
        />
    </nav>
</template>

<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { usePage, Link } from '@inertiajs/vue3';
import ArrowLink from '@/Components/ArrowLink.vue';

defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);

const menu = ref(null);

const materials = computed(() => usePage().props.shop.materials);

const close = () => emit('close');

onClickOutside(menu, close);
</script>

<template>
    <div
        ref="menu"
        class="sub-menu sub-menu-columns"
        :class="{ hidden: !isOpen }"
    >
        <div class="mb-8 md:break-after-column break-after-auto">
            <ArrowLink url="#" @click="close">
                {{ $t('navigation.product_finder') }}
            </ArrowLink>
            <ArrowLink url="#" @click="close">
                {{ $t('navigation.steel_wiki') }}
            </ArrowLink>
        </div>
        <ul class="space-y-4">
            <template v-for="material in materials" :key="material.id">
                <li>
                    <Link
                        class="flex items-center gap-2 link link-hover text-base-300"
                        :href="
                            route('shop.show-material', {
                                material: material.id,
                            })
                        "
                        @click="close"
                    >
                        <div
                            v-if="material.icon"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img :src="material.icon" class="w-full h-full" />
                        </div>
                        <div
                            v-else-if="material.image"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img
                                :src="material.image"
                                class="object-cover w-full h-full"
                            />
                        </div>
                        {{ material.title }}
                    </Link>
                </li>
                <li v-for="child in material.children" :key="child.id">
                    <Link
                        class="flex items-center gap-2 link link-hover text-base-300"
                        :href="
                            route('shop.show-material', {
                                material: child.id,
                            })
                        "
                        @click="close"
                    >
                        <div
                            v-if="material.icon"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img :src="material.icon" class="w-full h-full" />
                        </div>
                        <div
                            v-else-if="child.image"
                            class="w-4 h-4 shrink-0"
                            aria-hidden
                        >
                            <img
                                :src="child.image"
                                class="object-cover w-full h-full"
                            />
                        </div>
                        {{ child.title }}
                    </Link>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { Link, usePage } from '@inertiajs/vue3';
import { useCurrentLocale } from '@/Composables/useCurrentLocale';

defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    'close',
    'showSearchMenu',
    'showProductTypesMenu',
    'showMaterialsMenu',
    'changeLocale',
]);

const menu = ref(null);

const currentLocale = computed(() => useCurrentLocale());
const locales = computed(() => usePage().props.config.locales);

const close = () => emit('close');
const showSearchMenu = () => emit('showSearchMenu');
const showProductTypesMenu = () => emit('showProductTypesMenu');
const showMaterialsMenu = () => emit('showMaterialsMenu');
const changeLocale = (locale) => emit('changeLocale', locale);

onClickOutside(menu, close);
</script>

<template>
    <div
        ref="menu"
        class="menu dropdown-content sub-menu"
        :class="{ hidden: !isOpen }"
    >
        <ul>
            <li>
                <Link
                    :href="route('shop.index')"
                    :class="{
                        active: route().current('shop.index'),
                    }"
                    @click="close"
                >
                    {{ $t('navigation.shop') }}
                </Link>
            </li>
            <li>
                <button type="button" @click="showProductTypesMenu">
                    {{ $t('navigation.product_range') }}
                </button>
            </li>
            <li>
                <button type="button" @click="showMaterialsMenu">
                    {{ $t('navigation.material') }}
                </button>
            </li>
            <li>
                <Link href="#" @click="close">
                    {{ $t('navigation.services') }}
                </Link>
            </li>
            <li>
                <Link href="#" @click="close">
                    {{ $t('navigation.about') }}
                </Link>
            </li>
            <li>
                <Link href="#" @click="close">
                    {{ $t('navigation.support') }}
                </Link>
            </li>
            <li>
                <button type="button" @click="showSearchMenu">
                    {{ $t('navigation.search') }}
                </button>
            </li>
            <li>
                <Link
                    :href="route('cart.index')"
                    :class="{
                        active: route().current('cart.index'),
                    }"
                    @click="close"
                >
                    {{ $t('navigation.cart') }}
                </Link>
            </li>
            <li>
                <Link
                    :href="route('dashboard')"
                    :class="{
                        active: route().current('dashboard'),
                    }"
                    @click="close"
                >
                    {{ $t('navigation.profile') }}
                </Link>
            </li>
            <li>
                <details>
                    <summary>
                        {{ $t('language.languages') }}
                    </summary>
                    <ul>
                        <li v-for="locale in locales" :key="locale.locale">
                            <a
                                :class="{
                                    active:
                                        locale.locale === currentLocale.locale,
                                }"
                                @click="changeLocale(locale.locale)"
                            >
                                {{ $t('language.' + locale.locale) }}
                            </a>
                        </li>
                    </ul>
                </details>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePage, Link } from '@inertiajs/vue3';
import Logo from '@assets/logos/logo.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import InstagramIcon from '@assets/icons/instagram.svg';
import VisaIcon from '@assets/icons/visa.svg';
import MastercardIcon from '@assets/icons/mastercard.svg';
import MaestroIcon from '@assets/icons/maestro.svg';
import AmexIcon from '@assets/icons/amex.svg';
import KlarnaIcon from '@assets/icons/klarna.svg';
import EpsIcon from '@assets/icons/eps.svg';
import GiropayIcon from '@assets/icons/giropay.svg';
import PayPalIcon from '@assets/icons/paypal.svg';

const socialLinks = usePage().props.config.socialLinks;

const productTypes = computed(() => usePage().props.shop.productTypes);
const materials = computed(() => usePage().props.shop.materials);

const year = new Date().getFullYear();
</script>

<template>
    <footer class="w-full px-4 py-16 text-white bg-primary">
        <div class="container mx-auto">
            <div class="grid grid-flow-row grid-cols-1 gap-8 lg:grid-cols-4">
                <aside class="lg:col-span-2">
                    <Logo class="w-full h-auto max-w-xs" />
                </aside>
                <nav>
                    <h6 class="footer-title">
                        {{ $t('footer.top.left.title') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <li>
                            <Link
                                class="link link-hover"
                                :href="route('shop.index')"
                            >
                                {{ $t('footer.top.left.shop') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.top.left.services') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.top.left.about') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.top.left.support') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="link link-hover"
                                :href="route('cart.index')"
                            >
                                {{ $t('footer.top.left.cart') }}
                            </Link>
                        </li>
                        <li>
                            <Link
                                class="link link-hover"
                                :href="route('login')"
                            >
                                {{ $t('footer.top.left.login') }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav>
                    <h6 class="footer-title">
                        {{ $t('footer.top.right.title') }}
                    </h6>
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.top.right.faq') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.top.right.wiki') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.top.right.contact') }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav>
                    <div class="flex items-center gap-4">
                        <a
                            v-if="socialLinks.facebook"
                            :href="socialLinks.facebook"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FacebookIcon class="w-auto h-16" />
                        </a>
                        <a
                            v-if="socialLinks.instagram"
                            :href="socialLinks.instagram"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <InstagramIcon class="w-auto h-16" />
                        </a>
                    </div>
                </nav>
            </div>
            <div class="divider-white divider"></div>
            <div class="grid grid-flow-row grid-cols-1 gap-8 lg:grid-cols-4">
                <nav v-if="productTypes.length" class="lg:col-span-2">
                    <h6 class="footer-title">
                        {{ $t('product-type.product_types') }}
                    </h6>
                    <ul
                        class="space-y-2 font-mono text-xs list-none columns-2sm"
                    >
                        <template
                            v-for="productType in productTypes"
                            :key="productType.id"
                        >
                            <li>
                                <Link
                                    class="link link-hover"
                                    :href="
                                        route('shop.show-product-type', {
                                            productType: productType.id,
                                        })
                                    "
                                >
                                    {{ productType.title }}
                                </Link>
                            </li>
                            <li
                                v-for="child in productType.children"
                                :key="child.id"
                            >
                                <Link
                                    class="link link-hover"
                                    :href="
                                        route('shop.show-product-type', {
                                            productType: child.id,
                                        })
                                    "
                                >
                                    {{ child.title }}
                                </Link>
                            </li>
                        </template>
                    </ul>
                </nav>
                <nav v-if="materials.length">
                    <h6 class="footer-title">{{ $t('material.materials') }}</h6>
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <template
                            v-for="material in materials"
                            :key="material.id"
                        >
                            <li>
                                <Link
                                    class="link link-hover"
                                    :href="
                                        route('shop.show-material', {
                                            material: material.id,
                                        })
                                    "
                                >
                                    {{ material.title }}
                                </Link>
                            </li>
                            <li
                                v-for="child in material.children"
                                :key="child.id"
                            >
                                <Link
                                    class="link link-hover"
                                    :href="
                                        route('shop.show-material', {
                                            material: child.id,
                                        })
                                    "
                                >
                                    {{ child.title }}
                                </Link>
                            </li>
                        </template>
                    </ul>
                </nav>
                <nav>
                    <h6 class="footer-title">
                        {{ $t('footer.bottom.right.title') }}
                    </h6>
                    <div class="flex flex-row flex-wrap gap-4 mb-8 max-w-96">
                        <VisaIcon class="w-auto h-8" />
                        <MastercardIcon class="w-auto h-8" />
                        <MaestroIcon class="w-auto h-8" />
                        <AmexIcon class="w-auto h-8" />
                        <KlarnaIcon class="w-auto h-8" />
                        <EpsIcon class="w-auto h-8" />
                        <GiropayIcon class="w-auto h-8" />
                        <PayPalIcon class="w-auto h-8" />
                    </div>
                    <ul class="space-y-2 font-mono text-xs list-none">
                        <li>
                            <Link class="link link-hover" href="#">
                                {{
                                    $t(
                                        'footer.bottom.right.terms_and_conditions',
                                    )
                                }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.bottom.right.privacy_policy') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.bottom.right.cookie_manager') }}
                            </Link>
                        </li>
                        <li>
                            <Link class="link link-hover" href="#">
                                {{ $t('footer.bottom.right.privacy_center') }}
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="container mx-auto mt-16">
            <div
                class="grid grid-flow-row grid-cols-1 gap-8 font-mono text-xs lg:grid-cols-4"
            >
                <p class="uppercase">
                    © {{ $t('footer.all_rights_reserved') }} {{ year }}
                </p>
                <p class="col-span-3">
                    <i18n-t keypath="footer.website_by" scope="global">
                        <template #brisk>
                            <a
                                href="#"
                                rel="noopener noreferrer"
                                target="_blank"
                                class="link link-hover"
                            >
                                Studio Brisk
                            </a>
                        </template>
                        <template #dotbite>
                            <a
                                href="https://dotbite.at"
                                rel="noopener noreferrer"
                                target="_blank"
                                class="link link-hover"
                            >
                                Dotbite
                            </a>
                        </template>
                    </i18n-t>
                </p>
            </div>
        </div>
    </footer>
</template>

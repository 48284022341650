<script setup>
import { Link } from '@inertiajs/vue3';
import ArrowRight from '@assets/icons/arrow_right.svg';

const emit = defineEmits(['click']);

defineProps({
    title: {
        type: String,
        required: true,
    },
    productType: {
        type: Object,
    },
    material: {
        type: Object,
    },
    image: {
        type: String,
    },
    url: {
        type: String,
        required: true,
    },
});

const onClick = () => emit('click');
</script>

<template>
    <Link
        :href="url"
        class="flex flex-row items-center w-full gap-4 p-4 text-sm font-semibold rounded-lg bg-base-200/30 card"
        @click="onClick"
    >
        <div v-if="image" class="w-6 h-6 shrink-0" aria-hidden>
            <img :src="image" class="object-cover w-full h-full" />
        </div>
        <div class="flex flex-row flex-wrap gap-4 items-between">
            <div class="text-primary">
                {{ title }}
            </div>
            <div
                v-if="material || productType"
                class="gap-4 font-mono text-base-300"
            >
                {{ material.title }}
                {{ productType.title }}
            </div>
        </div>
        <div class="flex items-center justify-end flex-grow">
            <div
                class="flex items-center justify-center w-10 h-10 min-h-0 p-0 bg-white rounded-full"
            >
                <ArrowRight class="relative w-4 h-4 text-primary" />
            </div>
        </div>
    </Link>
</template>
